
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import SmsSentDetailsSingle from '@/components/pages/history/smsSent/details/SmsSentDetailsSingle.vue'
import type { SMSReceivedSent } from '@/definitions/history/types'

export default defineComponent({
  components: {
    TmModal,
    SmsSentDetailsSingle,
  },
  props: {
    message: {
      type: Object as PropType<SMSReceivedSent>,
      required: true,
    },
  },
})
