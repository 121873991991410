import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sms_sent_details_single = _resolveComponent("sms-sent-details-single")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "historySmsInfo",
    size: "xLarge",
    "modal-title": "Sent SMS",
    "no-footer": ""
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_sms_sent_details_single, { message: _ctx.message }, null, 8, ["message"])
    ]),
    _: 1
  }))
}